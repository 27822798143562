<template>
  <header
    class="bg-[--surface-card] fixed top-0 left-0 w-full z-50 border-gray-600 border-b-2"
  >
    <div
      class="bg-black text-center w-full p-2 text-sm md:text-base"
      v-if="loggedIn && !user.confirmed"
    >
      {{ $t('app.confirm_email') }}
      <Button
        @click="handleConfirmationEmailResend"
        :label="$t('app.here')"
        text
        class="align-middle p-0 ps-1 mb-1"
      />
    </div>
    <div
      class="flex justify-between items-center flex-shrink-0 md-big:pr-8 pr-4 ps-3 md-big:ps-0 py-2"
    >
      <div class="md-big:hidden">
        <button
          @click="$emit('toggle-sidebar')"
          class="text-white p-2 focus:outline-none"
        >
          <Icon icon="fa-solid fa-bars" class="text-2xl" />
        </button>
      </div>
      <NuxtLink to="/">
        <img
          :src="$path + '/img/' + website.img_folder + '/partner-logo.png'"
          class="ms-8 h-10 md-big:h-14"
        />
      </NuxtLink>

      <div class="flex">
        <div class="hidden md-big:flex items-center">
          <a
            href="https://twitter.com/cryptosfaucets"
            target="_blank"
            class="twitter mr-4"
          >
            <Icon icon="fa-brands fa-twitter"></Icon>
          </a>
          <a
            href="https://youtube.com/@freecryptos"
            target="_blank"
            class="youtube mr-4"
          >
            <Icon icon="fa-brands fa-youtube"></Icon>
          </a>
          <a
            href="https://www.instagram.com/freecryptosfaucets/"
            target="_blank"
            class="instagram mr-4"
          >
            <Icon icon="fa-brands fa-instagram"></Icon>
          </a>
          <a
            href="https://t.me/freecryptosfaucets"
            target="_blank"
            class="telegram mr-8"
          >
            <Icon icon="fa-brands fa-telegram"></Icon>
          </a>

          <template v-if="loggedIn">
            <LayoutBalance v-if="website.currency" />
            <NuxtLink
              v-if="user.unread_message_count > 0"
              to="/messages"
              class="ms-4 me-2 flex items-center"
            >
              <span class="relative">
                <Icon icon="fa-regular fa-envelope " class="text-2xl" />
                <span
                  class="absolute -top-2.5 -right-2.5 bg-orange-500 rounded-xl py-0 px-[5px] text-[12px] font-bold text-white font-sans"
                  >{{ user.unread_message_count }}</span
                >
              </span>
            </NuxtLink>
          </template>
          <template v-else
            >1$ =
            {{
              formatAmount(website.exchange_rate) + ' ' + website.currency
            }}</template
          >
        </div>
        <LayoutUserOptions v-if="loggedIn" />
      </div>
    </div>
  </header>
</template>

<script setup>
import { useWebsiteInfoStore } from '@/stores/website';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';

const api = useApi();
const toast = useToast();
const websiteInfoStore = useWebsiteInfoStore();
const userStore = useUserStore();
const { t } = useI18n();

const website = computed(() => websiteInfoStore.website);
const user = computed(() => userStore.user);
const loggedIn = computed(() => userStore.loggedIn);

const handleConfirmationEmailResend = async () => {
  const response = await api.execute('/api/ajax-resend-confirmation', 'post');

  let message = t('app.email_resent');
  if (response.error == 'throttle') {
    message = t('app.email_already_sent');
  }

  toast.add({
    severity: response.status ? 'info' : 'warn',
    summary: 'Email confirmation',
    detail: message,
    life: 3000,
  });
};
watch(website, (newValue) => {
  useHead({
    link: [
      {
        rel: 'icon',
        type: 'image/svg',
        href: `/favicon/${newValue.img_folder}.svg`,
      },
    ],
    titleTemplate: (title) => `${newValue.fancy_domain} - ${title}`,
  });
});
</script>
