<template>
  <div class="flex">
    <Dropdown
      @change="setSelectedBalance"
      v-model="selectedBalance"
      :options="balanceOptions"
      modelValue="currency"
      optionValue="currency"
      class="w-72 h-10 items-center"
    >
      <template #value="slotProps">
        <div v-if="slotProps.value">
          <span class="font-medium text-gray-400 mr-4">{{
            slotProps.value
          }}</span>
          <span>
            {{ getBalanceByCurrency(slotProps.value) }}
          </span>
        </div>
      </template>
      <template #option="slotProps">
        <div class="flex justify-between">
          <span class="font-medium text-gray-400">{{
            slotProps.option.currency
          }}</span>
          <div>
            <p>
              {{
                formatNumber(
                  user[slotProps.option.amount_key],
                  slotProps.option.decimals
                )
              }}
            </p>
          </div>
        </div>
      </template>
    </Dropdown>
    <Button
      class="h-10 p-2 px-5 rounded-lg border-slate-600 ms-4 hover:bg-slate-700"
      @click="toggleDeposit"
    >
      {{ $t('app.deposit') }}
      <Icon icon="fa-solid fa-plus" class="ml-1" />
    </Button>
  </div>
</template>
<script setup>
import { useWebsiteInfoStore } from '@/stores/website';
import { useUserStore } from '@/stores/user';

const websiteInfoStore = useWebsiteInfoStore();
const userStore = useUserStore();

const website = computed(() => websiteInfoStore.website);
const selectedBalance = ref(website.value.currency);
const user = computed(() => userStore.user);

const balanceOptions = ref([
  {
    currency: website.value.currency,
    amount_key: 'total_coins',
    decimals: calculatePrecision(user.value.total_coins),
  },
  { currency: 'TOKENS', amount_key: 'total_tokens', decimals: 0 },
]);

const setSelectedBalance = (bal) => {
  const cookie = useCookie('display_balance');
  cookie.value = bal.value;
};

onMounted(() => {
  const cookie = useCookie('display_balance').value;

  if (cookie) {
    selectedBalance.value = cookie;
  }
});

const getBalanceByCurrency = (cur) => {
  const bal = balanceOptions.value.find((bal) => bal.currency === cur);
  return bal ? formatNumber(user.value[bal.amount_key], bal.decimals) : '';
};

const toggleDeposit = () => {
  userStore.toggleDepositPopup();
};
</script>
