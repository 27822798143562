<template>
  <div class="flex justify-content-center mr-2 md:m-0 relative">
    <Button
      type="button"
      class="py-1 px-2 w-auto ml-4 h-9"
      @click="toggle"
      aria-haspopup="true"
      aria-controls="overlay_menu"
    >
      <Icon icon="fa-regular fa-user" class="text-xl mb-0.5"></Icon>
    </Button>
    <Transition @after-enter="afterEnter" @after-leave="afterLeave">
      <div class="absolute right-0 top-9" v-if="visible" ref="menu">
        <Menu id="overlay_menu" :model="items">
          <template #submenuheader="{ item }">
            <span>{{ $t(item.label) }}</span>
          </template>
          <template #start v-if="loggedIn">
            <div
              class="md-big:hidden border-b-[1px] border-slate-600 ps-5 pb-2 text-gray-300 mt-2"
            >
              <span class="block">{{ formatAmount(user?.total_coins) }} </span>
              <small>{{ website.currency }}</small>
              <span class="block mt-1">{{ user?.total_tokens }} </span>
              <small>TOKENS</small>
              <Button
                class="h-10 p-2 px-5 rounded-lg hover:bg-slate-700 block my-2"
                @click="toggleDeposit"
              >
                {{ $t('app.deposit') }}
                <Icon icon="fa-solid fa-plus" class="ml-1" />
              </Button>
            </div>
          </template>
          <template #item="{ item, props }">
            <NuxtLink
              v-if="item.route"
              v-ripple
              class="p-menuitem-link"
              activeClass="bg-slate-600"
              :to="item.route"
            >
              <Icon :icon="item.icon" class="p-menuitem-icon" />
              <span class="ml-2 p-menuitem-text">{{ $t(item.label) }}</span>
            </NuxtLink>
            <a
              v-else
              v-ripple
              class="flex align-items-center"
              v-bind="props.action"
            >
              <Icon :icon="item.icon" class="p-menuitem-icon" />
              <span class="ml-2 p-menuitem-text">{{ $t(item.label) }}</span>
            </a>
          </template>
        </Menu>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { useUserStore } from '@/stores/user';
import { useWebsiteInfoStore } from '@/stores/website';

const userStore = useUserStore();
const websiteInfoStore = useWebsiteInfoStore();

const items = ref([
  {
    label: 'app.user_options',
    items: [
      {
        label: 'app.settings',
        icon: 'fa-solid fa-gear',
        route: '/settings',
      },
      {
        label: 'app.withdrawals',
        icon: 'fa-solid fa-wallet',
        route: '/withdrawals',
      },
      {
        label: 'deposits.title',
        icon: 'fa-solid fa-money-bill-transfer',
        route: '/deposits',
      },
      {
        label: 'app.logout',
        icon: 'fa-solid fa-right-from-bracket',
        command: async () => {
          await userStore.logout();
          navigateTo('/');
        },
      },
    ],
  },
]);

const visible = ref(false);
const menu = ref();
const user = computed(() => userStore.user);
const loggedIn = computed(() => userStore.loggedIn);
const website = computed(() => websiteInfoStore.website);

const handleGlobalClick = (event) => {
  if (menu.value && !menu.value.contains(event.target)) {
    visible.value = false;
  }
};

const afterEnter = () => document.addEventListener('click', handleGlobalClick);

const afterLeave = () =>
  document.removeEventListener('click', handleGlobalClick);

const toggle = () => {
  visible.value = !visible.value;
};

const toggleDeposit = () => {
  userStore.toggleDepositPopup();
};
</script>
