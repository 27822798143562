<template>
  <div
    class="px-8 bg-[--surface-card] fixed h-full top-0 left-0 w-72 flex flex-col z-10 pt-28"
    :class="{
      'pt-36': loggedIn && !user.confirmed,
      'pt-40': loggedIn && !user.confirmed && isMobile,
    }"
  >
    <div class="flex flex-col h-full min-w-max">
      <div class="overflow-y-auto">
        <div v-if="loggedIn">
          <ul class="list-none p-0 m-0 overflow-hidden">
            <li>
              <NuxtLink
                activeClass="text-teal-500 font-bold [&>span]:text-slate-200"
                class="flex align-center cursor-pointer pb-3 border-round text-700 transition-duration-150 transition-colors p-ripple"
                to="/games"
                @click="$emit('hide-sidebar')"
              >
                <Icon
                  icon="fa-solid fa-gamepad"
                  class="mr-2.5 text-2xl mt-1"
                ></Icon>
                <span class="leading-8">{{ $t('app.games') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                activeClass="text-teal-500 font-bold [&>span]:text-slate-200"
                class="flex align-center cursor-pointer py-3 border-round text-700 transition-duration-150 transition-colors p-ripple"
                @click="$emit('hide-sidebar')"
                to="/free"
              >
                <Icon icon="fa-solid fa-gift" class="text-2xl mr-4"></Icon>
                <span class="leading-8">{{
                  $t('app.free', { currency: website.currency })
                }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                activeClass="text-teal-500 font-bold [&>span]:text-slate-200"
                class="flex align-center cursor-pointer py-3 border-round text-700 transition-duration-150 transition-colors p-ripple"
                to="/earn-more"
                @click="$emit('hide-sidebar')"
              >
                <Icon
                  icon="fa-regular fa-money-bill-1"
                  class="text-2xl mr-3 mt-0.5"
                ></Icon>
                <span class="leading-8">{{ $t('app.earn_more') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                activeClass="text-teal-500 font-bold [&>span]:text-slate-200"
                class="flex align-center cursor-pointer py-3 border-round text-700 transition-duration-150 transition-colors p-ripple"
                to="/referral-program"
                @click="$emit('hide-sidebar')"
              >
                <Icon icon="fa-solid fa-user-group" class="mr-2 text-2xl" />
                <span class="leading-8">{{ $t('app.referral_program') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                activeClass="text-teal-500 font-bold [&>span]:text-slate-200"
                class="flex align-center cursor-pointer py-3 border-round text-700 transition-duration-150 transition-colors p-ripple"
                to="/faq"
                @click="$emit('hide-sidebar')"
              >
                <Icon icon="fa-solid fa-question " class="ms-1 mr-5 text-2xl" />
                <span class="leading-8">{{ $t('app.faq') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                activeClass="text-teal-500 font-bold [&>span]:text-slate-200"
                class="flex align-center cursor-pointer py-3 border-round text-700 transition-duration-150 transition-colors p-ripple"
                to="/stats"
                @click="$emit('hide-sidebar')"
              >
                <Icon
                  icon="fa-solid fa-chart-line"
                  class="mr-4 text-2xl mt-0.5"
                />
                <span class="leading-8">{{ $t('app.stats') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                activeClass="text-teal-500 font-bold [&>span]:text-slate-200"
                class="flex align-center cursor-pointer py-3 border-round text-700 transition-duration-150 transition-colors p-ripple"
                to="/free-rolls"
                @click="$emit('hide-sidebar')"
              >
                <Icon icon="fa-regular fa-circle" class="mr-4 text-2xl" />
                <span class="leading-8">{{ $t('app.free_rolls') }}</span>
              </NuxtLink>
            </li>
          </ul>
          <ul class="border-t-[1px] border-gray-700 py-3 mt-3">
            <li>
              <NuxtLink
                activeClass="text-teal-500 font-bold [&>span]:text-slate-200"
                class="flex align-center cursor-pointer py-3 border-round text-700 transition-duration-150 transition-colors p-ripple"
                @click="toggleDeposit"
              >
                <Icon icon="fa-solid fa-plus" class="mr-3 text-2xl mt-0.5" />
                <span class="leading-8">{{ $t('app.deposit') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                activeClass="text-teal-500 font-bold [&>span]:text-slate-200"
                class="flex align-center cursor-pointer py-3 border-round text-700 transition-duration-150 transition-colors p-ripple"
                to="/withdraw-now"
                @click="$emit('hide-sidebar')"
              >
                <Icon
                  icon="fa-regular fa-credit-card "
                  class="mr-3 text-2xl mt-0.5"
                />
                <span class="leading-8">{{ $t('app.withdraw') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                activeClass="text-teal-500 font-bold [&>span]:text-slate-200"
                class="flex align-center cursor-pointer py-3 border-round text-700 transition-duration-150 transition-colors p-ripple"
                to="/redeem-tokens"
                @click="$emit('hide-sidebar')"
              >
                <Icon icon="fa-solid fa-hands" class="mr-3 text-2xl mt-0.5" />
                <span class="leading-8">{{
                  $t('earn-more.redeem_tokens', { currency: website.currency })
                }}</span>
              </NuxtLink>
            </li>
          </ul>
        </div>
        <ul v-else class="list-none p-0 m-0 overflow-hidden">
          <li>
            <NuxtLink
              class="flex align-center cursor-pointer py-3 border-round text-700 transition-duration-150 transition-colors p-ripple"
              :to="website.full_path"
              @click="$emit('hide-sidebar')"
            >
              <Icon icon="fa-solid fa-home" class="mr-3 text-2xl" />
              <span class="leading-8">{{ $t('hp.home') }}</span>
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              class="flex align-center cursor-pointer py-3 border-round text-700 transition-duration-150 transition-colors p-ripple"
              to="/login"
              @click="$emit('hide-sidebar')"
            >
              <Icon
                icon="fa-solid fa-right-to-bracket"
                class="mr-4 text-2xl"
              ></Icon>
              <span class="leading-8">{{ $t('hp.login') }}</span>
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              class="flex align-center cursor-pointer py-3 border-round text-700 transition-duration-150 transition-colors p-ripple"
              :to="website.full_path"
              @click="$emit('hide-sidebar')"
            >
              <Icon icon="fa-solid fa-user-plus" class="mr-2 text-2xl"></Icon>
              <span class="leading-8">{{ $t('hp.register') }}</span>
            </NuxtLink>
          </li>
        </ul>
      </div>
      <div class="locale-changer mt-4 pt-10 border-t-[1px] border-gray-700">
        <Dropdown
          @change="setLocale"
          v-model="selectedLang"
          :options="langs"
          optionValue="code"
          optionLabel="name"
          modelValue="name"
          class="w-full md:w-14rem"
          appendTo=".locale-changer"
        >
          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-center">
              <i
                :class="getLangIcon(slotProps.value)"
                class="inline-block w-[20px] h-[20px] mr-1"
              ></i>
              <div>
                {{ getLanguageName(slotProps.value) }}
              </div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="flex align-center">
              <i
                :class="slotProps.option.icon"
                class="inline-block w-[20px] h-[20px] mr-1"
              ></i>
              <div>
                {{ slotProps.option.name }}
              </div>
            </div>
          </template>
        </Dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useWebsiteInfoStore } from '@/stores/website';
import { useUserStore } from '@/stores/user';

const websiteInfoStore = useWebsiteInfoStore();
const userStore = useUserStore();
const { $i18n } = useNuxtApp();

const website = computed(() => websiteInfoStore.website);
const loggedIn = computed(() => userStore.loggedIn);
const user = computed(() => userStore.user);
const screenInfo = useScreenSize();
const isMobile = computed(() => screenInfo.isMobile.value);

const langs = ref([
  { name: 'العربية', code: 'ar', icon: 'ar-icon' },
  { name: 'Dansk', code: 'da', icon: 'da-icon' },
  { name: 'Deutsch', code: 'de', icon: 'de-icon' },
  { name: 'English', code: 'en', icon: 'en-icon' },
  { name: 'Español', code: 'es', icon: 'es-icon' },
  { name: 'Français', code: 'fr', icon: 'fr-icon' },
  { name: 'Bahasa Indonesia', code: 'id', icon: 'id-icon ' },
  { name: '日本語', code: 'ja', icon: 'ja-icon' },
  { name: '한국어', code: 'ko', icon: 'ko-icon' },
  { name: 'Polski', code: 'pl', icon: 'pl-icon ' },
  { name: 'Português', code: 'pt', icon: 'pt-icon' },
  { name: 'Русский', code: 'ru', icon: 'ru-icon' },
  { name: 'ไทย', code: 'th', icon: 'th-icon' },
  { name: '简体中文', code: 'zh - CHS', icon: 'zh-chs-icon' },
  { name: '繁體中文', code: 'zh - CHT', icon: 'zh-cht-icon ' },
]);

const selectedLang = ref('en');

const getLanguageName = (code) => {
  if (Array.isArray(langs.value)) {
    const lang = langs.value.find((lang) => lang.code === code);
    return lang ? lang.name : '';
  }
  return '';
};

const getLangIcon = (code) => {
  if (Array.isArray(langs.value)) {
    const lang = langs.value.find((lang) => lang.code === code);
    return lang ? lang.icon : '';
  }
  return '';
};

const setLocale = (locale) => {
  $i18n.locale.value = locale.value;
  useCookie('lang', { domain: `.${website.value.domain}` }).value =
    locale.value;
};

const emit = defineEmits(['hide-sidebar']);

const toggleDeposit = () => {
  emit('hide-sidebar');
  userStore.toggleDepositPopup();
};

onMounted(() => {
  const langCookie = useCookie('lang').value;

  if (langCookie) {
    $i18n.locale.value = langCookie;
    selectedLang.value = langCookie;
  }
});
</script>
