<template>
  <footer>
    <div v-if="website" class="ms-2 md-big:ms-72 md-big:w-[calc(100%-18rem)]">
      <section class="w-full bg-opacity-10 text-center pt-10">
        <a
          v-for="partner in partners"
          :key="partner.domain"
          :href="`https://${partner.domain}`"
          target="_blank"
          ><img
            class="opacity-100 partners-img h-7 sm:h-12 md:h-16 xl:h-auto my-1 mx-1 xl:my-[15px] xl:mx-[30px]"
            :src="`${$path}/img/${partner.img_folder}`"
        /></a>
      </section>
      <div class="p-8 text-center">
        <p>
          {{ moment().year() }}, {{ website.fancy_domain }} -
          {{ $t('app.copyright') }}
        </p>
        <p class="mt-2">
          <NuxtLink class="cursor-pointer underline" to="gdpr"
            >{{ $t('app.gdpr') }}
          </NuxtLink>
          |
          <a class="underline" target="_blank" :href="`${$path}/blog`">{{
            $t('app.blog')
          }}</a>
          <span v-if="website.name === 'FreePancake'">
            |
            <a
              class="underline"
              href="https://g.ezoic.net/privacy/freepancake.com"
              target="_blank"
              >Privacy Policy</a
            >
            <a
              class="underline"
              href="https://twitter.com/cryptosfaucets"
              target="_blank"
              >Contact Us</a
            >
          </span>

          <span v-for="article in articles" :key="article.slug">
            |
            <a
              class="underline"
              target="_blank"
              :href="`${$path}/articles/${article.slug}`"
              >{{ article.footer_title }}</a
            >
          </span>
        </p>
      </div>
    </div>
  </footer>
  <div
    v-if="website.ad_3 && showAd"
    class="fixed bottom-0 w-full z-50 block sm:hidden"
  >
    <Icon
      @click="closeAd"
      icon="fa-regular fa-circle-xmark"
      class="text-4xl absolute right-2.5 top-0 rounded-full bg-black bg-opacity-70 text-white leading-4 transform -translate-y-1/2"
    ></Icon>
    <div v-html="website.ad_3"></div>
  </div>

  <div
    v-if="website.ad_4 && showAd"
    class="fixed bottom-0 left-0 right-0 flex justify-center bg-black bg-opacity-80 z-50 h-22 cursor-pointer font-sans overflow-visible hidden sm:block"
  >
    <div class="flex justify-center" v-html="website.ad_4"></div>

    <Icon
      @click="closeAd"
      icon="fa-regular fa-circle-xmark"
      class="text-4xl absolute right-2.5 top-0 rounded-full bg-black bg-opacity-70 text-white leading-4 transform -translate-y-1/2"
    ></Icon>
  </div>
</template>
<script setup>
import { useWebsiteInfoStore } from '@/stores/website';
import moment from 'moment';

const websiteInfoStore = useWebsiteInfoStore();

const website = computed(() => websiteInfoStore.website);
const articles = computed(() => websiteInfoStore.footerArticles);
const partners = computed(() => websiteInfoStore.partners);

const showAd = ref(true);

const closeAd = () => {
  showAd.value = false;
};
</script>
