<template>
  <div class="min-h-screen relative">
    <LayoutTopBar @toggle-sidebar="toggleSidebar" />

    <LayoutSideBar @hide-sidebarr="hideSidebar" class="hidden md-big:block" />
    <Sidebar
      class="md-big:hidden"
      v-model:visible="showSidebar"
      :autoZIndex="false"
      ><LayoutSideBar @hide-sidebar="hideSidebar"
    /></Sidebar>
    <!-- Page Content -->
    <main
      class="w-full pt-24 md-big:pt-32 ms-0 md-big:ms-72 md-big:w-[calc(100%-18rem)]"
      :class="{
        'pt-44 md-big:pt-44': user && !user.confirmed,
      }"
    >
      <div class="container">
        <slot />
      </div>
    </main>
    <Toast position="bottom-right" />
    <LayoutMainFooter />
    <LayoutDeposit />
  </div>
</template>

<script setup>
import { useWebsiteInfoStore } from '~/stores/website';
import { useUserStore } from '@/stores/user';
import { useToast } from 'primevue/usetoast';

import { ref } from 'vue';

const showSidebar = ref(false);
const userStore = useUserStore();

const websiteInfoStore = useWebsiteInfoStore();

const user = computed(() => userStore.user);
const loggedIn = computed(() => userStore.loggedIn);
const website = computed(() => websiteInfoStore.website);
const toast = useToast();

watch(user, (newValue) => {
  if (newValue && newValue.id) {
    listenToUser();
  }
});

onMounted(async () => {
  await websiteInfoStore.fetchWebsiteInfo();

  await userStore.fetchUser();

  installAnalytics();

  window.Echo.channel('global').listen(
    '.App\\Events\\UpdateCoinmaster',
    (e) => {
      websiteInfoStore.updateWebsiteInfoFromBroadcast(e.websites);
    }
  );

  if (loggedIn.value) {
    listenToUser();
  }
});

const listenToUser = () => {
  window.Echo.private(`App.User.${website.value.id}.${user.value.id}`)
    .listen('.App\\Events\\UpdateTokens', (e) => {
      userStore.updateUser({ total_tokens: e.total_tokens });
    })
    .listen('.App\\Events\\NewDeposit', (e) => {
      toast.add({
        severity: 'success',
        summary: 'Deposit Received',
        detail: `A new deposit of ${e.amount} ${e.currency} was received and is waiting confirmation`,
        life: 10000,
      });
    })
    .listen('.App\\Events\\ConfirmedDeposit', (e) => {
      toast.add({
        severity: 'success',
        summary: 'Deposit Confirmed',
        detail: `The deposit of ${e.amount} ${e.currency} is now confirmed`,
        life: 10000,
      });
    });
};

const toggleSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const hideSidebar = () => {
  showSidebar.value = false;
};

const installAnalytics = () => {
  const scriptContainer = document.createElement('div');
  scriptContainer.innerHTML = website.value.google_analytics;

  const scriptElements = scriptContainer.querySelectorAll('script');

  scriptElements.forEach((scriptElement) => {
    const newScriptTag = document.createElement('script');

    const srcAttribute = scriptElement.getAttribute('src');

    if (srcAttribute) {
      newScriptTag.src = srcAttribute;
    } else {
      newScriptTag.textContent = scriptElement.textContent;
    }
    document.body.appendChild(newScriptTag);
  });
};
</script>
